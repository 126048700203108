<template>
  <h1></h1>
</template>

<script>
export default {
  created() {},
};
</script>

<style>
</style>